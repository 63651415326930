import { Cancel as CancelIcon } from '@mui/icons-material';
import { Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { debounce, uniqueId } from 'lodash';
import { FC, useCallback, useState } from 'react';

import { DocusignsProps } from './interfaces';

import './index.scss';

import { useApolloClient, useMutation } from '@apollo/client';
import { AlertDialog, ConfirmationDialog, UserInitials } from 'components';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { useUI } from 'contexts/UiContext';
import {
  CONTRACT_DOCUSIGN_DOWNLOAD_ENVELOPE,
  CONTRACT_DOCUSIGN_ENVELOPE_STATUS_UPDATE,
} from 'graphql/legalFolders/docusign';
import { contractDocuSignEnvelopeDownload } from 'graphql/legalFolders/types/contractDocuSignEnvelopeDownload';
import LoadingOverlay from 'react-loading-overlay-ts';
import { carbonCopiesByOrder, signersByOrder } from 'utils/docusign';
import { capitalizeFirstLetter } from 'utils/formats';
import { id } from 'utils/id';
import { saveToFile } from 'utils/saveToFile';
import { formatTime } from 'utils/time';

const useStyles = makeStyles((theme) => ({
  hoverWarning: {
    '&:hover': {
      color: 'red',
    },
  },
}));

interface ISelectedEnvelope {
  envelopeId: string;
}

export const Docusigns: FC<DocusignsProps> = ({
  documentId,
  versions,
  docusigns,
  documentArchived,
  projectFolderDocument,
  refetchDocument,
}: DocusignsProps) => {
  const classes = useStyles();
  const client = useApolloClient();
  const { addSnackbar } = useUI();
  const [downloading, setDownloading] = useState<string | undefined>();
  const [selectedEnvelope, setSelectedEnvelope] = useState<ISelectedEnvelope | undefined>();
  const [voidEnvelopeConfirmationOpen, setVoidEnvelopeConfirmationOpen] = useState(false);
  const [statusReason, setStatusReason] = useState<string | undefined>();

  const [isRefetching, setIsRefetching] = useState(false);

  const [mutationErrorMessage, setMutationErrorMessage] = useState('');
  const [mutationErrorOpen, setMutationErrorOpen] = useState(false);

  const [voidEnvelope, { loading: voidEnvelopeLoading }] = useMutation(
    CONTRACT_DOCUSIGN_ENVELOPE_STATUS_UPDATE
  );

  const somethingIsLoading = useCallback(
    (envelopeId: string): boolean => {
      return (
        ((voidEnvelopeLoading || isRefetching) && selectedEnvelope?.envelopeId === envelopeId) ||
        envelopeId === downloading
      );
    },
    [isRefetching, voidEnvelopeLoading, selectedEnvelope, downloading]
  );

  const voidEnvelopeHandler = useCallback((docusign: any, version: any) => {
    if (!docusign) return;
    const envelopeId = docusign.envelopeId;
    setSelectedEnvelope({ envelopeId });
    setVoidEnvelopeConfirmationOpen(true);
  }, []);

  const voidEnvelopeConfirmationCloseHandler = useCallback(
    (ok: boolean): void => {
      setVoidEnvelopeConfirmationOpen(false);

      if (ok) {
        setIsRefetching(true);

        voidEnvelope({
          variables: {
            documentId: documentId,
            envelopeId: selectedEnvelope?.envelopeId,
            status: 'voided',
            statusReason,
          },
        })
          .then(() => {
            setSelectedEnvelope(undefined);
            refetchDocument({ variables: { id: id() } }).then(() => {
              setIsRefetching(false);
            });
            addSnackbar!({
              text: 'Success',
              severity: 'success',
            });
          })
          .catch((e) => {
            setMutationErrorMessage(e?.message);
            setMutationErrorOpen(true);
            setSelectedEnvelope(undefined);
          });
      }
      setStatusReason('');
    },
    [documentId, refetchDocument, selectedEnvelope, statusReason, voidEnvelope, addSnackbar]
  );

  const handleDownloadDocusign = useCallback(
    (docusign: any, version: any, documentType: any) => {
      const envelopeId = docusign.envelopeId;
      const fileName = version?.blobName + '_' + envelopeId;

      return async () => {
        setDownloading(envelopeId);
        try {
          const { data, error } = await client.query<contractDocuSignEnvelopeDownload>({
            query: CONTRACT_DOCUSIGN_DOWNLOAD_ENVELOPE,
            variables: {
              envelopeId,
              documentType,
            },
            fetchPolicy: 'network-only',
          });
          if (error) {
            addSnackbar!({ text: 'Error', severity: 'error' });
            console.log('Error', error);
          } else {
            if (!data?.contract_documentDocusignEnvelopeDownload?.fileBase64) {
              addSnackbar!({ text: 'Error', severity: 'error' });
            } else {
              const fileBase64 = data.contract_documentDocusignEnvelopeDownload.fileBase64;
              const buffer = Buffer.from(fileBase64, 'base64');

              // const blob = new Blob([new Uint8Array(buffer)]);
              const blob = new Blob([buffer], { type: 'application/octet-stream' });
              saveToFile(blob, fileName + '_' + documentType, '.pdf');
            }
          }
        } catch (e: any) {
          addSnackbar!({ text: 'Error', severity: 'error' });
          console.log('Error', e);
        }
        setDownloading(undefined);
      };
    },
    [client, addSnackbar]
  );

  return (
    <div className="document-section">
      <AlertDialog
        title="Error"
        message={mutationErrorMessage}
        open={mutationErrorOpen}
        onClose={() => {
          setMutationErrorOpen(false);
        }}
      />
      <ConfirmationDialog
        title="DocuSign Document Status Change"
        message={'Are you sure you want to Void the DocuSign document?'}
        comment={statusReason}
        setComment={setStatusReason}
        open={voidEnvelopeConfirmationOpen}
        onClose={voidEnvelopeConfirmationCloseHandler}
        commentRequired={true}
        commentPlaceholderText="Void Reason (mandatory)"
      />

      {docusigns
        .slice()
        .sort((a: any, b: any) => +new Date(b?.createdAt) - +new Date(a?.createdAt))
        .map((docusign: any, i: number) => {
          const signersByRoutingOrder = signersByOrder(docusign);
          const ccByRoutingOrder = carbonCopiesByOrder(docusign);

          const version = versions.find((v) => v.id === docusign.documentVersionId);
          if (!version) return undefined;
          return (
            <LoadingOverlay active={somethingIsLoading(docusign?.envelopeId)} text="Please wait...">
              <Grid container spacing={1}>
                <>
                  <Grid item xs={10} key={uniqueId()}>
                    <Typography style={{ fontSize: '0.88rem', paddingBottom: 5 }}>
                      <strong style={{ fontWeight: 900 }}>
                        {version?.event?.author?.persona
                          ? version?.event?.author?.persona + ' - '
                          : ''}
                        {version?.event?.author?.user?.name ? (
                          <>{version?.event?.author?.user?.name}</>
                        ) : (
                          'Undefined Uploader'
                        )}
                      </strong>
                    </Typography>

                    <div
                      className={
                        documentArchived && i > 0
                          ? 'file-archive-item-readon-only'
                          : 'file-archive-item'
                      }
                      style={{
                        overflow: 'hidden',
                        // wordBreak: 'break-all',
                        // wordWrap: 'break-word',
                      }}
                      onClick={handleDownloadDocusign(docusign, version, 'combined')}
                    >
                      Download DocuSign Document
                    </div>

                    {docusign?.envelope?.status === 'completed' ? (
                      <div
                        className={
                          documentArchived && i > 0
                            ? 'file-archive-item-readon-only'
                            : 'file-archive-item'
                        }
                        style={{
                          overflow: 'hidden',
                          // wordBreak: 'break-all',
                          // wordWrap: 'break-word',
                        }}
                        onClick={handleDownloadDocusign(docusign, version, 'certificate')}
                      >
                        Download Certificate
                      </div>
                    ) : undefined}
                    <div style={{ padding: '1rem 0' }}>
                      DocuSign Status:{' '}
                      <div
                        style={{
                          display: 'inline-block',
                          color: docusign?.envelope?.status === 'voided' ? 'red' : undefined,
                        }}
                      >
                        {capitalizeFirstLetter(docusign?.envelope?.status || 'unknown')}
                      </div>
                      {docusign?.envelope?.statusChangedDateTime ? (
                        <>
                          <br />
                          Status Changed: {formatTime(docusign?.envelope?.statusChangedDateTime)}
                        </>
                      ) : undefined}
                      {docusign?.envelope?.voidedReason ? (
                        <>
                          <br />
                          <div
                            style={{
                              display: 'inline-block',
                              border: '1px solid #ccc',
                              padding: '11px 10px',
                              borderRadius: '3px',
                              width: '85%',
                            }}
                          >
                            {docusign?.envelope?.voidedReason}
                          </div>
                        </>
                      ) : undefined}
                      {/* <br />
                        Request initiated: {formatTime(docusign?.createdAt)} */}
                      {docusign?.envelope?.createdDateTime ? (
                        <>
                          <br />
                          Created: {formatTime(docusign?.envelope?.createdDateTime)}
                        </>
                      ) : undefined}
                      {docusign?.envelope?.lastModifiedDateTime ? (
                        <>
                          <br />
                          Last Modified: {formatTime(docusign?.envelope?.lastModifiedDateTime)}
                        </>
                      ) : undefined}
                      {docusign?.envelope?.completedDateTime ? (
                        <>
                          <br />
                          Completed: {formatTime(docusign?.envelope?.completedDateTime)}
                        </>
                      ) : undefined}
                      {docusign?.envelope?.expireEnabled &&
                      docusign?.envelope?.expireDateTime &&
                      !docusign?.envelope?.completedDateTime &&
                      docusign?.envelope?.status !== 'voided' ? (
                        <>
                          <br />
                          Expire Date: {formatTime(docusign?.envelope?.expireDateTime)}
                        </>
                      ) : undefined}
                      <br />
                      <br />
                      File Name:{' '}
                      <a
                        href={version?.downloadUrl || undefined}
                        style={{ color: '#000000de' }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {version?.blobName}
                      </a>
                      <br />
                      Original File Name: {version?.originalFilename}
                      <br />
                      Version v1.{version.version}
                      <br />
                      {signersByRoutingOrder.length ? (
                        <>
                          <br />
                          <strong>Signers:</strong>
                          <br />
                        </>
                      ) : undefined}
                      {signersByRoutingOrder.map(
                        ({ routingOrder, signers }: { routingOrder: number; signers: any[] }) => {
                          return signers?.map((signer: any) => {
                            return (
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'start',
                                    margin: '10px 0',
                                    gap: '10px',
                                  }}
                                >
                                  <Tooltip
                                    title={
                                      <>
                                        {`${signer.name} (${signer.email})`}
                                        <br />
                                        Status: {capitalizeFirstLetter(signer.status)}
                                        <br />
                                        Sign Order: #{routingOrder}
                                      </>
                                    }
                                  >
                                    <div
                                      style={{
                                        transform: 'scale(0.7)',
                                      }}
                                    >
                                      <UserInitials
                                        {...signer}
                                        divStyle={
                                          signer.status === 'sent'
                                            ? { backgroundColor: 'yellow', color: 'black' }
                                            : signer.status === 'completed'
                                            ? { backgroundColor: 'green' }
                                            : { backgroundColor: 'black', color: 'white' }
                                        }
                                      />
                                    </div>
                                  </Tooltip>

                                  <div>
                                    {`${signer.name}`}
                                    <br />
                                    {signer.email}
                                  </div>
                                </div>
                              </>
                            );
                          });
                        }
                      )}
                      <br />
                      {ccByRoutingOrder.length ? (
                        <>
                          <strong>Receives a copy:</strong>
                          <br />
                        </>
                      ) : undefined}
                      {ccByRoutingOrder.map(
                        ({ routingOrder, cc }: { routingOrder: number; cc: any[] }) => {
                          return cc?.map((cc: any) => {
                            return (
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'start',
                                    margin: '10px 0',
                                    gap: '10px',
                                  }}
                                >
                                  <Tooltip
                                    title={
                                      <>
                                        {`${cc.name} (${cc.email})`}
                                        <br />
                                        DocuSign Status: {capitalizeFirstLetter(cc.status)}
                                        <br />
                                        Routing Order: #{routingOrder}
                                      </>
                                    }
                                  >
                                    <div
                                      style={{
                                        transform: 'scale(0.7)',
                                      }}
                                    >
                                      <UserInitials
                                        {...cc}
                                        divStyle={
                                          cc.status === 'sent'
                                            ? { backgroundColor: 'yellow', color: 'black' }
                                            : cc.status === 'completed'
                                            ? { backgroundColor: 'green' }
                                            : { backgroundColor: 'black', color: 'white' }
                                        }
                                      />
                                    </div>
                                  </Tooltip>

                                  <div>
                                    {`${cc.name}`}
                                    <br />
                                    {cc.email}
                                  </div>
                                </div>
                              </>
                            );
                          });
                        }
                      )}
                    </div>

                    <Divider />
                    <br />
                  </Grid>
                  <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                    <div>
                      {docusign?.envelope?.status &&
                      !['completed', 'voided', 'declined'].includes(docusign?.envelope?.status) ? (
                        <IconButton
                          edge="end"
                          onClick={debounce(() => {
                            voidEnvelopeHandler(docusign, version);
                          }, DEBOUNCE_TIMEOUT)}
                          style={{ padding: '12px' }}
                          className={classes.hoverWarning}
                        >
                          <Tooltip
                            title={`Void the DocuSign Request`}
                            placement="bottom-start"
                            arrow
                          >
                            <div
                              style={{
                                display: 'flex',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <CancelIcon fontSize="small" />
                              </div>
                              <div
                                style={{
                                  paddingLeft: '5px',
                                  display: 'flex',
                                  fontSize: '0.875rem',
                                  alignItems: 'center',
                                }}
                              >
                                Void
                              </div>
                            </div>
                          </Tooltip>
                        </IconButton>
                      ) : undefined}
                    </div>
                  </Grid>
                </>
              </Grid>
            </LoadingOverlay>
          );
        })}
    </div>
  );
};
